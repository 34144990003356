// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-content-blog-test-mdx-first-page-mdx": () => import("/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/src/content/blog/test-mdx-first-page.mdx" /* webpackChunkName: "component---src-content-blog-test-mdx-first-page-mdx" */),
  "component---src-pages-404-js": () => import("/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-flower-js": () => import("/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/src/pages/Flower.js" /* webpackChunkName: "component---src-pages-flower-js" */),
  "component---src-pages-index-js": () => import("/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vegetable-js": () => import("/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/src/pages/Vegetable.js" /* webpackChunkName: "component---src-pages-vegetable-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/thierry/Documents/gitlab/dorloofarm/gatsby/dorloo/.cache/data.json")

